import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};
export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("@/view/pages/user/dashboard/index.vue"),
          beforeEnter: (to, from, next) => {
            const token = store && store.getters.isAuthenticated;
            if (!token && to.name !== "signin") {
              next({ name: "signin" });
            } else {
              if (to && to.query && to.query.navigate_to) {
                store.commit("setNavigateTo", to.query.navigate_to);
                if (to.query.navigate_to === "team_plans") {
                  next({ name: "plans" });
                } else next();
              } else next();
            }
          },
        },
        {
          path: "/apps",
          name: "apps",
          component: () => import("@/view/pages/app/list.vue"),
        },
        {
          path: "/app/:app_id",
          name: "app-dashboard",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/releases",
          name: "app-releases",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/members",
          name: "app-members",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/share",
          name: "app-share-link",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/share/settings",
          name: "app-share-settings",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/feedback",
          name: "app-feedback",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/subscribers",
          name: "app-subscribers",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/installs",
          name: "app-installs",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/timeline",
          name: "app-timeline",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/app/:app_id/chat",
          name: "app-chat",
          component: () => import("@/view/pages/app/index.vue"),
        },
        {
          path: "/dashboard",
          name: "Empty Dashboard",
          component: () => import("@/view/layout/DashboardEmpty.vue"),
          children: [
            {
              path: "/welcome",
              name: "welcome",
              component: () => import("@/view/pages/user/welcome/index.vue"),
            },
            {
              path: "/settings/api-credentials",
              name: "api-credentials",
              component: () =>
                import("@/view/pages/user/settings/api-credentials.vue"),
            },
            {
              path: "/get-started",
              name: "get-started",
              component: () =>
                import("@/view/pages/user/get-started/index.vue"),
            },
            {
              path: "/get-started/team",
              name: "started-team",
              component: () =>
                import("@/view/pages/user/get-started/index.vue"),
            },
            {
              path: "/get-started/app",
              name: "started-app",
              component: () =>
                import("@/view/pages/user/get-started/index.vue"),
            },
            {
              path: "/get-started/invite",
              name: "started-invite",
              component: () =>
                import("@/view/pages/user/get-started/index.vue"),
            },
            {
              path: "/get-started/release",
              name: "started-releases",
              component: () =>
                import("@/view/pages/user/get-started/index.vue"),
            },
            {
              path: "/team",
              name: "team",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/members",
              name: "team-members",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/invites",
              name: "invites",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/storage",
              name: "storage",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/plans",
              name: "plans",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/integrations",
              name: "integrations",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/timeline",
              name: "timeline",
              component: () => import("@/view/pages/team/index.vue"),
            },
            {
              path: "/team/integrations/slack",
              name: "slack-integration",
              component: () =>
                import("@/view/pages/team/integrations/slack.vue"),
            },
            {
              path: "/team/integrations/slack/auth",
              name: "slack-integration-auth",
              component: () =>
                import("@/view/pages/team/integrations/slack.vue"),
            },
            {
              path: "/team/integrations/testappio",
              name: "testappio",
              component: () =>
                import("@/view/pages/team/integrations/testappio.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/activate/:token",
      name: "activate",
      component: () => import("@/view/pages/user/auth/ActivateAccount.vue"),
    },
    {
      path: "/i/:invite_id",
      name: "invite",
      component: () => import("@/view/pages/team/invite/index.vue"),
    },
    //START STATIC PAGES
    {
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/static/404.vue"),
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/static/error.vue"),
    },
    {
      path: "/notification-settings",
      name: "manage-notification",
      component: () =>
        import("@/view/pages/static/notification_settings/uuid/index.vue"),
    },
    {
      path: "/notification-settings/:uuid",
      name: "notification-setting",
      component: () =>
        import("@/view/pages/static/notification_settings/uuid/index.vue"),
    },
    {
      path: "/start",
      name: "start",
      component: () => import("@/view/pages/static/start.vue"),
    },
    {
      path: "/mobile-app/download",
      name: "download-app",
      component: () => import("@/view/pages/static/mobile-app/download.vue"),
    },
    {
      path: "/mobile-app/install",
      name: "install-app",
      component: () => import("@/view/pages/static/mobile-app/install.vue"),
    },
    {
      path: "/mobile-app/update",
      name: "update-app",
      component: () => import("@/view/pages/static/mobile-app/update.vue"),
    },
    {
      path: "/mobile-app/update/android",
      name: "update-android-app",
      component: () => import("@/view/pages/static/mobile-app/update.vue"),
    },
    {
      path: "/mobile-app/update/ios",
      name: "update-ios-app",
      component: () => import("@/view/pages/static/mobile-app/update.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("@/view/pages/static/contact.vue"),
    },
    {
      path: "/udid",
      name: "udid",
      component: () => import("@/view/pages/static/udid.vue"),
    },
    {
      path: "/install/:appID",
      name: "install-old",
      component: () => import("@/view/pages/static/install-old.vue"),
    },
    {
      path: "/apps/install/:uid",
      name: "install",
      component: () => import("@/view/pages/static/install.vue"),
    },
    {
      path: "/",
      component: () => import("@/view/pages/user/auth/Auth"),
      children: [
        {
          name: "signin",
          path: "/signin",
          component: () => import("@/view/pages/user/auth/Signin"),
          meta: {},
          beforeEnter: (to, from, next) => {
            const token = store.getters.isAuthenticated;
            if (token) next({ name: "dashboard" });
            else next();
          },
        },
        {
          name: "signup",
          path: "/signup",
          component: () => import("@/view/pages/user/auth/Signup"),
          meta: {
            title: "TestApp.io - Portal",
            description:
              "Sign up for TestApp.io here. It is an app testing platform where developers can share their app Android (APK) & iOS (IPA) with others & get instant feedback.",
          },
          beforeEnter: (to, from, next) => {
            const token = store.getters.isAuthenticated;
            if (token) next({ name: "dashboard" });
            else next();
          },
        },
        {
          name: "appsumo",
          path: "/appsumo",
          component: () => import("@/view/pages/user/auth/appsumo"),
        },
        {
          name: "appsumo-code",
          path: "/appsumo/:code",
          component: () => import("@/view/pages/user/auth/appsumo"),
        },
        {
          name: "verify",
          path: "/verify",
          component: () => import("@/view/pages/user/auth/activate"),
          beforeEnter: (to, from, next) => {
            const token = store.getters.isAuthenticated;
            if (token) next({ name: "dashboard" });
            else next();
          },
        },
        {
          name: "forgot-password",
          path: "/forgot-password",
          component: () => import("@/view/pages/user/auth/ForgotPassword"),
          beforeEnter: (to, from, next) => {
            const token = store.getters.isAuthenticated;
            if (token) next({ name: "dashboard" });
            else next();
          },
        },
        {
          name: "reset-password",
          path: "/reset-password/:token",
          component: () => import("@/view/pages/user/auth/ResetPassword"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    /*
     *************
     ** Express **
     *************
     */
    {
      path: "/express",
      name: "upload",
      meta: {
        title: "TestApp.io - Portal Express",
        description:
          "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!",
      },
      component: () => import("@/view/pages/express/index.vue"),
    },
    // {
    //   path: "/express/external",
    //   name: "external-upload",
    //   meta: {
    //     title: "TestApp.io - Portal Express",
    //     description:
    //       "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!"
    //   },
    //   component: () => import("@/view/pages/express/single-upload.vue")
    // },
    {
      path: "/express/about",
      name: "about",
      meta: {
        title: "TestApp.io - Portal Express",
        description:
          "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!",
      },
      component: () => import("@/view/pages/express/index.vue"),
    },
    {
      path: "/express/apps",
      name: "express-apps",
      meta: {
        title: "TestApp.io - Portal Express",
        description:
          "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!",
      },
      component: () => import("@/view/pages/express/index.vue"),
    },
    {
      path: "/e/:releaseID",
      meta: {
        title: "TestApp.io - Portal Express",
        description:
          "With TestApp.io Express, you can share your app in testing on our platform with everyone without having to sign up. Check it out today!",
      },
      name: "express",
      component: () => import("@/view/pages/express/install.vue"),
    },
  ],
  mode: "history",
});
