import ApiService from "@/core/services/api.service.js";
export const GENERATE_SLACK_AUTH_LINK = "generateSlackAuthLink";
export const GET_INTEGRATION_LIST = "getIntegrationList";
export const ENABLE_SLACK = "enableSlack";
export const DISABLE_SLACK = "disableSlack";

const state = {
  integrationList: [],
};
const getters = {
  getIntegrationList() {
    return state.integrationList;
  },
};
const actions = {
  [GENERATE_SLACK_AUTH_LINK]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/team/integrations/slack/generate-auth-link`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_INTEGRATION_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/team/integrations`)
        .then(({ data }) => {
          resolve(data);
          context.commit("setIntegrationList", data.data || []);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ENABLE_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/portal/team/integrations/slack/enable`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DISABLE_SLACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/portal/team/integrations/slack`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  setIntegrationList(state, payload) {
    state.integrationList = payload || [];
  },
};

export default { actions, getters, mutations, state };
