// Global 3rd party mixins
import "./plugins/veeValidate";
import "popper.js";
import "tooltip.js";
// import "firebase/performance";
// import VueGtag from "vue-gtag";

import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "./router";
import ReadMore from "vue-read-more";
import globalMixin from "./mixins/index";
import ApiService from "./core/services/api.service";
import { VERIFY_AUTH, UPDATE_USER_TOKEN } from "@/store/users/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/store/common/config.module";
import { SWITCH_TEAM } from "@/store/team/manageTeam.module.js";

// Vue 3rd party mixins
import ClipboardJS from "clipboard";
import Clipboard from "v-clipboard";
import vuetify from "./plugins/vuetify";
import "firebase/analytics";
import firebase from "firebase/app";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
import Flag from "vue-flagpack";

import * as Sentry from "@sentry/vue";
import * as Integrations from "@sentry/integrations";

if (process.env.VUE_APP_ENV !== "local") {
  Sentry.init({
    dsn:
      "https://53b79d58aea74b8a9a25d046eba19aba@o555352.ingest.sentry.io/5693546",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: process.env.VUE_APP_RELEASE,
    environment: process.env.VUE_APP_ENV,
  });
}

window.ClipboardJS = ClipboardJS;
// vue meta
import Meta from "vue-meta";
Vue.use(Meta);
// theglobal dropown
Vue.component("VueInlineAlert", () =>
  import("@/view/components/Common/VueInlineAlert.vue")
);
Vue.component("ContentLoader", () =>
  import("@/view/components/Common/ContentLoader.vue")
);
Vue.component("VueHoverableDate", () =>
  import("@/view/components/Common/HoverableDate")
);
Vue.component("AutoComplete", () =>
  import("@/view/components/Common/AutoComplete.vue")
); // the info tooltip
Vue.component("ActionDropdown", () =>
  import("@/view/components/Common/ActionDropdown")
);
Vue.component("VueSnackBar", () =>
  import("@/view/components/Common/VueSnackbar.vue")
);
Vue.component("PlatformIcon", () =>
  import("@/view/components/App/PlatformIcon.vue")
); // sidePanel (navigation drawer)
Vue.component("VueTooltip", () =>
  import("@/view/components/Common/vueTooltip.vue")
); // sidePanel (navigation drawer)
Vue.component("SidePanel", () =>
  import("@/view/components/Common/SidePanel.vue")
); // lodaer when website loading
Vue.component("InlineSvg", () =>
  import("@/view/components/Common/InlineSvg.vue")
);
Vue.component("ProfileImage", () => import("@/view/components/User/Image.vue"));
Vue.component("PageWrapper", () =>
  import("@/view/components/Common/PageWrapper.vue")
);
Vue.component("Loader", () => import("@/view/components/Common/VueLoader.vue")); // no data found
Vue.component("MaxText", () => import("@/view/components/Common/MaxText.vue"));
Vue.component("noData", () => import("@/view/components/Common/NoData.vue")); // loader when the data inside the website loading, any data
Vue.component("Modal", () => import("@/view/components/Common/Modal.vue"));
Vue.component(VueQrcode.name, VueQrcode);

Vue.use(VueTour);
Vue.config.productionTip = true;
Vue.use(Clipboard); // this plugin for cpying text to clipboard
Vue.use(ReadMore); // for the readmore feature
Vue.mixin(globalMixin);
Vue.use(Flag, {
  name: "Flag",
});

ApiService.init(); // API service init

/** START PADDLE */
if (process.env.VUE_APP_PADDLE_ENV === "sandbox") {
  window.Paddle.Environment.set("sandbox");
}
if (window && window.Paddle) {
  window.Paddle.Setup({ vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR) });
}

/** START FIREBASE */
firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

// firebase.initializeApp({
//   databaseURL: "https://testappio-7b07e.firebaseio.com",
//   appId: "1:369795138425:web:322f3b752fb59ffc99a5a8",
//   apiKey: "AIzaSyCsjgUx07JJagG7VwVmfdFGCn9g5uxzxyk",
//   authDomain: "testappio-7b07e.firebaseapp.com",
//   storageBucket: "testappio.appspot.com",
//   messagingSenderId: "369795138425",
//   measurementId: "G-R9RLZC85TN",
//   projectId: "testappio",
// });

// firebase.analytics();
// firebase.performance();

// Vue.prototype.$firebase = firebase;
Vue.prototype.$analytics = firebase.analytics();

/** START G-TAG for Google - Needs to be under firebase */
// Vue.use(VueGtag, {
//   config: {
//     // id: "G-K92RNP2G58",
//     params: {
//       send_page_view: true,
//     },
//   },
// });

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(() => next());

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  // Vue.prototype.$analytics.setCurrentScreen(to.fullPath); // sets `screen_name` parameter
  // Vue.prototype.$analytics.logEvent("screen_view"); // log event with `screen_name` parameter attached

  // Vue.prototype.$gtag.pageview({
  //   page_path: to.fullPath,
  // });
});

//@Dipesh: It needs to be removed and added somewhere else and not to be called each time a route change!!! The SEO part.
router.afterEach((to, from) => {
  if (to && to.query && to.query.plan) {
    let description = `Check out our ${to.query.plan}! Sign up for our app testing platform where developers share their app Android (APK) & iOS (IPA) with others & get instant feedback.`;
    document.querySelector("meta[name='description']").content = description;
    let link = document.createElement("link");
    link.rel = "canonical";
    link.href = "https://portal.testapp.io/signup";
    document.head.appendChild(link);
  }
  Vue.nextTick(() => {
    document.title = to.meta.title || "TestApp.io - Portal";
    if (to && to.query && to.query.team_id) {
      const currentTeamId = store.getters.getTeamId;
      if (
        to.query.team_id !== currentTeamId &&
        !["slack-integration-auth", "slack-integration"].includes(to.name)
      ) {
        store
          .dispatch(SWITCH_TEAM, to.query.team_id)
          .then((response) => {
            store.commit("setSwitchModal", true);
            store.dispatch(UPDATE_USER_TOKEN, response.data.token);
            setTimeout(() => {
              window.location.href = window.location.href.split("?")[0];
            }, 2000);
          })
          .catch((err) => {
            store.commit("notifyErrorMessage", err.message);
            window.history.replaceState(null, null, window.location.pathname);
          });
      } else {
        window.history.replaceState(null, null, window.location.pathname);
      }
    }
  });
  if (store && store.getters.isAuthenticated) {
    /** Segment */
    if (process.env.VUE_APP_ENV !== "local") {
      window.analytics.page(to.name, {
        path: to.fullPath,
        referrer: from.fullPath,
      });
    }
  }
});

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
