import ApiService from "@/core/services/api.service";

// action types
export const GET_ROLES = "getRoles";
export const SET_ROLES = "setRoles";
export const SET_PLANS = "setPlans";
export const GET_PLANS = "getPlans";
export const CHANGE_PLANS = "changePlans";
export const ADD_ADDONS = "addAddons";
export const REMOVE_ADDONS = "removeAddons";
export const START_FREE_TRIAL = "startFreeTrial";
export const CANCEL_PLANS = "cancelPlans";
export const GET_MEMBER_DEVICE_LIST = "getMemberDeviceList";
export const GET_MEMBER_APPS_LIST = "getMemberAppsList";
export const CLEAR_PLANS_DATA = "ClearPlansData";
const state = {
  roles: [],
  plans: [],
  // StorageService.getItem("roles") || [],
};

const getters = {
  getRoles(state) {
    return state.roles;
  },
  getPlans(state) {
    return state.plans;
  },
};

const actions = {
  [GET_PLANS](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/plan`)
        .then(async ({ data }) => {
          await context.commit(SET_PLANS, data.data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHANGE_PLANS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/plan`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [ADD_ADDONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/addons`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REMOVE_ADDONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/team/plan/addon`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [START_FREE_TRIAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/plan/trial`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CANCEL_PLANS](context, payload) {
    return new Promise((reslove, reject) => {
      ApiService.delete(`/core/team/plan`, { ...payload })
        .then(response => {
          reslove(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_MEMBER_DEVICE_LIST](_, memberId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/${memberId}/devices`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_MEMBER_APPS_LIST](_, memberId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/member/${memberId}/apps`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_ROLES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/team/roles`)
        .then(({ data }) => {
          context.commit(SET_ROLES, data.data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_ROLES](state, roles) {
    state.roles = [...roles];
  },
  [SET_PLANS](state, roles) {
    state.plans = roles;
  },
  [CLEAR_PLANS_DATA](state) {
    state.roles = [];
    state.plans = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
