import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      userPermissions: "userPermissions",
      isInfoLoaded: "isInfoLoaded"
    }),
    // Can manage app can do Create, Delete and Update of Apps.
    canManageApp() {
      return !!(this.userPermissions.app && this.userPermissions.app.manage);
    },
    // This permission can only view invite list.
    canViewInvite() {
      return !!(
        this.userPermissions.invite && this.userPermissions.invite.view
      );
    },
    canManageInvite() {
      return !!(
        this.userPermissions.invite && this.userPermissions.invite.manage
      );
    },
    // canViewRelease() {
    //   return !!(
    //     this.userPermissions.release && this.userPermissions.release.view
    //   );
    // },
    canManageRelease() {
      return !!(
        this.userPermissions.release && this.userPermissions.release.manage
      );
    },
    canManageTeam() {
      return !!(this.userPermissions.team && this.userPermissions.team.manage);
    },
    canManageStorage() {
      return !!(
        this.userPermissions.storage && this.userPermissions.storage.manage
      );
    },
    canViewStorage() {
      return !!(
        this.userPermissions.storage && this.userPermissions.storage.view
      );
    },
    canManageTeamMembers() {
      return !!(
        this.userPermissions.member && this.userPermissions.member.manage
      );
    },
    canViewTeamMembers() {
      return !!(
        this.userPermissions.member && this.userPermissions.member.view
      );
    },
    canViewTimeline() {
      return !!(
        this.userPermissions.timeline && this.userPermissions.timeline.view
      );
    }
  }
};
