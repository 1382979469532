import ApiService from "@/core/services/api.service";
// action types
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const CHECK_MEMBERS = "checkMembers";
export const REMOVE_MEMBER = "removeMembers";
export const SET_ALL_MEMBERS = "setAllMembers";
export const CLEAR_TEAM_MEMBER_DATA = "clearTeamMemberData";
export const DISABLE_ENABLE_MEMBERS = "disableEnableMembers";

const state = {
  allMembers: [],
  totalMembersCount: 0,
  totalMembersData: [],
};

const getters = {
  getAllMembers(state) {
    return state.allMembers;
  },
  getTotalMembersData(state) {
    return state.totalMembersData;
  },
  getTotalMembersCount(state) {
    return state.totalMembersCount;
  },
};

const actions = {
  [CHECK_MEMBERS](context, lastId) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/team/members?lastID=${lastId || ""}`)
        .then(({ data }) => {
          if (data.results && data.results.data) {
            if (!lastId) {
              context.commit(SET_ALL_MEMBERS, data.results.data);
              context.commit(
                "setTotalMembersCount",
                data.results && data.results.meta ? data.results.meta.count : ""
              );
            } else {
              context.commit("setMembersPaginatedData", data.results.data);
            }
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          // it settign no data just stoping the loader
          context.commit(SET_ALL_MEMBERS);
        });
    });
  },
  [UPDATE_MEMBER](context, updatedData) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.put(`core/team/member/${updatedData.id}`, updatedData.data)
        .then(({ data }) => {
          context.commit("notifyUserMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error.message || error);
        });
    });
  },
  [DISABLE_ENABLE_MEMBERS](context, payload) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.post(`core/team/member/${payload.id}/status`, payload)
        .then(({ data }) => {
          context.commit("notifyUserMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error.message || error);
        });
    });
  },
  [REMOVE_MEMBER](context, id) {
    // reset the messages
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.delete(`core/team/member/${id}`)
        .then(({ data }) => {
          context.commit("notifyUserMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("notifyErrorMessage", error);
        });
    });
  },
};

const mutations = {
  [SET_ALL_MEMBERS](state, data) {
    // place each data to there places
    if (data) {
      state.totalMembersData = data;
      state.allMembers = data;
    }
    state.memberInvitesLoader = false;
  },
  removeMemberFromList(state, payload) {
    state.allMembers = state.allMembers.filter((x) => x.id !== payload.id);
    state.totalMembersData = state.totalMembersData.filter(
      (x) => x.id !== payload.id
    );
    if (payload.status == 1) {
      state.totalMembersCount = state.totalMembersCount
        ? state.totalMembersCount - 1
        : state.totalMembersCount;
    }
  },
  setAppMemberList(state, payload) {
    state.allMembers = state.allMembers.map((member) => {
      if (member.id === payload.id) {
        member = payload;
      }
      return member;
    });
  },
  updateAllMemebers(state, payload) {
    state.allMembers.splice(payload.index, 1, payload.dataToAppend);
    state.totalMembersData.splice(payload.index, 1, payload.dataToAppend);
  },
  setTotalMembersCount(state, payload) {
    state.totalMembersCount = payload;
  },
  setMembersPaginatedData(state, data) {
    let members = state.totalMembersData.length
      ? state.totalMembersData
      : state.allMembers;
    members = [...members, ...data];
    state.allMembers = [...data];
    state.totalMembersData = [...members];
  },
  setPreviousMembersData(state, data) {
    state.allMembers = [...data];
  },
  [CLEAR_TEAM_MEMBER_DATA](state) {
    state.allMembers = [];
    state.totalMembersCount = 0;
    state.totalMembersData = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
