import * as emitter from "emitter-io";

var socketInstance;

const connect = () => {
  socketInstance = emitter.connect({
    host: process.env.VUE_APP_SOCKET_URL,
    port: "",
    secure: process.env.VUE_APP_SOCKET_SECURE == "true",
  });

  return socketInstance;
};

const socket = function() {
  return socketInstance;
};

export default { connect, socket };
