// import { get } from "lodash";
import ApiService from "@/core/services/api.service";

// action types
export const GET_NOTIFICATIONS = "getNotifications";

// mutation types
export const SET_NOTIFICATIONS = "setNotifications";
export const SET_LOADERS = "setLoader";
export const CLEAR_NOTIFICATION_DATA = "clearNotificationData";

const state = {
  // init the notifications
  allNotifications: {
    // for the alerts
    alert: [],
  },
  // this is when data are loading by default will be true but when data done fetching or ther is no data will be false
  notificationsLoader: {
    invite: true,
    alert: true,
  },
};

const getters = {
  // for the notifications counter
  // notificationsCounter(state) {
  //   return (
  //     state.allNotifications.alert.unread.length +
  //     state.allNotifications.alert.read.length +
  //     state.allNotifications.invite.length
  //   );
  // },
};

const actions = {
  [GET_NOTIFICATIONS](context, params) {
    return new Promise((resolve, reject) => {
      // init a alerts var
      let alert = {
        read: [],
        unread: [],
      };

      // get all the notifications
      ApiService.get(
        `core/notifications?lastID=${
          params && params.lastId ? params.lastId : ""
        }`
      )
        .then(({ data }) => {
          //  loop throught the data
          data.data.map((v) =>
            v.data.map((v) => {
              //  if alert has propertie read = 0 set it to read if else set it to unread >> alert = {read: [], unread: []}
              if (v.read == 1) {
                alert.read.push(v);
              } else {
                alert.unread.push(v);
              }
            })
          );
          resolve(data);
          context.commit(SET_LOADERS);
        })
        .catch((error) => {
          reject(error);
        });

      context.commit(SET_NOTIFICATIONS, alert);
    });
  },
};

const mutations = {
  [SET_NOTIFICATIONS](state, payload) {
    // place each data to there places
    state.allNotifications.alert = payload;
  },
  [SET_LOADERS](state) {
    // place each data to there places
    state.notificationsLoader.alert = false;
  },
  [CLEAR_NOTIFICATION_DATA](state) {
    state.allNotifications = {
      // for the alerts
      alert: [],
    };
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
