import ApiService from "@/core/services/api.service";
import { isEmpty } from "@/core/services/helper.service";
// action types
export const GET_INVITE = "getInvite";
export const CREAT_INVITE = "creatInvite";
export const GET_ALL_INVITES = "getAllInvites";
export const UPDATE_INVITES = "updateInvites";
export const DELETE_INVITES = "deleteInvites";
export const ACCEPT_DECLINE = "accept_decline";
export const INVITE_BY_EMAIL = "INVITE_BY_EMAIL";
export const INVITE_BY_URL = "INVITE_BY_URL";
export const GET_STARTED = "getStarted";
export const CLEAR_INVITE_DATA = "clearInviteData";

const state = {
  inviteErrMsg: null,
  inviteSuccMsg: null,
  inviteLoader: null,
  inviteURL: null,
  listOfInvites: null,
  totalInvitesCount: "",
  totalListOfInvites: [],
  inviteInfo: null,
  emailInvites: null,
  urlInvites: null,
  totalEmailInviteCount: 0,
  totalUrlInviteCount: 0,
  totalEmailInvites: [],
  totalUrlInvites: [],
  notificationInviteList: [],
  inviteProcces: null,
  inviteUrlList: null,
  dashboardInviteLoader: true,
  emailsLoader: true,
  urlsLoader: true,
};

const getters = {
  getTotalInviteList(state) {
    return state.totalListOfInvites;
  },
  getNotificationInviteList(state) {
    return state.notificationInviteList;
  },
  getTotalInvitesListCount(state) {
    return state.totalInvitesCount;
  },
  getEmailList(state) {
    return state.emailInvites;
  },
  getEmailTotalList(state) {
    return state.totalEmailInvites;
  },
  getUrlTotalList(state) {
    return state.totalUrlInvites;
  },
  getEmailTotalCount(state) {
    return state.totalEmailInviteCount;
  },
  getUrlTotalCount(state) {
    return state.totalUrlInviteCount;
  },
  getUrlList(state) {
    return state.urlInvites;
  },
  getInviteUrl(state) {
    return state.inviteUrlList;
  },
};

const actions = {
  [GET_INVITE](context, invite_id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/invite/${invite_id}`)
        .then(({ data }) => {
          context.commit("setInviteInfo", data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_STARTED](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/invite/${params.id}/get-started`, {
        email: params.email,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CREAT_INVITE](context, invite_data) {
    // reset the messages
    context.commit("setSuccMessage", "");
    context.commit("setErrMessage", "");

    // set the loader
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/team/invite`, invite_data)
        .then(({ data }) => {
          context.commit("setInviteURL", data.url);
          context.commit("setInviteLoader", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setInviteLoader", false);
          reject(error);
        });
    });
  },
  [UPDATE_INVITES](context, invite_data) {
    // reset the messages
    context.commit("setErrMessage", "");
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/invite/${invite_data.id}`, invite_data.data)
        .then(({ data }) => {
          resolve(data);
          context.commit("notifyUserMessage", data.message || data);
          context.commit("setInviteLoader", false);
        })
        .catch((error) => {
          context.commit("notifyUserMessage", error.message || error);
          context.commit("setInviteLoader", false);
          reject(error);
        });
    });
  },
  [DELETE_INVITES](context, id) {
    // reset the messages
    context.commit("setSuccMessage", "");
    context.commit("setErrMessage", "");
    context.commit("setInviteLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/team/invite/${id}`)
        .then(({ data }) => {
          context.commit("setSuccMessage", data.message || data);
          context.commit("setInviteLoader", false);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setErrMessage", error.message || error);
          context.commit("setInviteLoader", false);

          reject(error);
        });
    });
  },
  // for the dashbord empty
  [GET_ALL_INVITES](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/invites?lastID=${params ? params.lastId || "" : ""}`)
        .then(({ data }) => {
          if (params && params.invites) {
            if (params.lastId) {
              context.commit(
                "setTotalInvitesListPaginatedData",
                data.data[0].data
              );
            } else {
              context.commit("setListOfInvites", data.data[0].data);
              context.commit(
                "setTotalInvitesDataCount",
                data.data[0].meta ? data.data[0].meta.total : 0
              );
            }
          }
          resolve(data);
        })
        .catch(function(error) {
          // it setting no data just stopping the loader
          context.commit("setListOfInvites");
          reject(error);
        });
    });
  },
  //
  [ACCEPT_DECLINE](context, { id, actionType }) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/invite/${id}/${actionType}`)
        .then(({ data }) => {
          context.commit("setSuccMessage", data.message || data);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setErrMessage", error.message || error);
          reject(error);
        });
    });
  },
  [INVITE_BY_EMAIL](context, params) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.get(
        `core/team/invites?by=email&lastID=${
          params ? params.lastID || "" : ""
        }&appID=${params ? params.appID || "" : ""}`
      )
        .then(({ data }) => {
          if (params && params.lastID) {
            context.commit("setEmailInvitePaginatedData", data.results.data);
          } else {
            context.commit("setEmailInvites", data.results.data);
            context.commit(
              "setEmailTotalInviteCount",
              data.results && data.results.meta ? data.results.meta.count : 1
            );
            context.commit(
              "setTotalMembersCount",
              data.results && data.results.meta
                ? data.results.meta.total_members
                : 0
            );
          }
          context.commit("setInviteListURL", data.url);
          resolve(data);
        })
        .catch((error) => {
          context.commit("setEmailInvites");
          reject(error);
        });
    });
  },
  [INVITE_BY_URL](context, params) {
    return new Promise((resolve, reject) => {
      // get all the Invites
      ApiService.get(
        `core/team/invites?by=url&lastID=${
          params ? params.lastID || "" : ""
        }&appID=${params ? params.appID || "" : ""}`
      )
        .then(({ data }) => {
          if (params && params.lastID) {
            context.commit("setUrlInvitePaginatedData", data.results.data);
          } else {
            context.commit("setUrlInvites", data.results.data);
            context.commit("setUrlTotalInviteCount", data.results.meta.count);
            context.commit(
              "setTotalMembersCount",
              data.results && data.results.meta
                ? data.results.meta.total_members
                : 0
            );
          }
          context.commit("setInviteListURL", data.url);
          resolve(data);
        })
        .catch(function(error) {
          // it setting no data just stoping the loader
          context.commit("setUrlInvites");
          reject(error);
        });
    });
  },
};

const mutations = {
  clearInvitesList(state, payload) {
    state.emailInvites = payload;
    state.urlInvites = payload;
    state.inviteUrlList = payload;
  },
  setEmailTotalInviteCount(state, payload) {
    state.totalEmailInviteCount = payload;
  },
  setUrlTotalInviteCount(state, payload) {
    state.totalUrlInviteCount = payload;
  },
  setEmailInvitePaginatedData(state, data) {
    let invite = state.totalEmailInvites.length
      ? state.totalEmailInvites
      : state.emailInvites;
    invite = [...invite, ...data];
    state.emailInvites = [...data];
    state.totalEmailInvites = [...invite];
  },
  setUrlInvitePaginatedData(state, data) {
    let invite = state.totalUrlInvites.length
      ? state.totalUrlInvites
      : state.urlInvites;
    invite = [...invite, ...data];
    state.urlInvites = [...data];
    state.totalUrlInvites = [...invite];
  },
  setPreviousEmailInviteData(state, data) {
    state.emailInvites = [...data];
  },
  setPreviousUrlInviteData(state, data) {
    state.urlInvites = [...data];
  },
  whenUserInInviteProcess(state, payload) {
    state.inviteProcces = payload;
  },
  removeFromEmail(state, id) {
    state.emailInvites = state.emailInvites.filter((ele) => ele.id !== id);
    state.totalEmailInvites = state.totalEmailInvites.filter(
      (ele) => ele.id !== id
    );
  },
  removeFromUrl(state, id) {
    state.urlInvites = state.urlInvites.filter((ele) => ele.id !== id);
    state.totalUrlInvites = state.totalUrlInvites.filter(
      (ele) => ele.id !== id
    );
  },
  setEmailInvites(state, data) {
    if (!isEmpty(data)) {
      state.emailInvites = data;
      state.totalEmailInvites = data;
    }
    state.emailsLoader = false;
  },
  appendInviteByEmail(state, data) {
    if (state.emailInvites && state.emailInvites.length) {
      if (data.id && !data.create) {
        const index = state.emailInvites.findIndex((el) => el.id === data.id);
        state.emailInvites.splice(index, 1, data);
      } else {
        state.emailInvites = [data, ...state.emailInvites];
      }
    } else if (data.append) {
      state.emailInvites = [];
      state.emailInvites.unshift(data);
    }
    return;
  },
  appendInviteByUrl(state, data) {
    if (state.urlInvites && state.urlInvites.length) {
      if (data.id && !data.create) {
        const index = state.urlInvites.findIndex((el) => el.id === data.id);
        state.urlInvites.splice(index, 1, data);
      } else {
        state.urlInvites = [data, ...state.urlInvites];
      }
    } else if (data.append) {
      state.urlInvites = [];
      state.urlInvites.unshift(data);
    }
    return;
  },
  setUrlInvites(state, data) {
    if (data && !isEmpty(data)) {
      state.totalUrlInvites = data;
      state.urlInvites = data;
    }
    state.urlsLoader = false;
  },
  setInviteInfo(state, info) {
    state.inviteInfo = info;
  },
  // this will set a message when data complete send, error or secces
  setListOfInvites(state, list) {
    !isEmpty(list) ? (state.listOfInvites = list) : (state.listOfInvites = []);
    !isEmpty(list)
      ? (state.totalListOfInvites = list)
      : (state.listOfInvites = []);
    state.dashboardInviteLoader = false;
  },
  setNotificationInviteList(state, data) {
    if (state.notificationInviteList && state.notificationInviteList.length) {
      state.notificationInviteList = [...state.notificationInviteList, ...data];
    } else {
      state.notificationInviteList = [...data];
    }
  },
  clearNotificationInviteList(state) {
    state.notificationInviteList = [];
  },
  setTotalInvitesListPaginatedData(state, data) {
    let invite = state.totalListOfInvites.length
      ? state.totalListOfInvites
      : state.listOfInvites;
    invite = [...invite, ...data];
    state.listOfInvites = [...data];
    state.totalListOfInvites = [...invite];
  },
  setTotalInvitesPreviousData(state, payload) {
    state.listOfInvites = [...payload];
  },
  setTotalInvitesDataCount(state, payload) {
    state.totalInvitesCount = payload;
  },
  setInviteURL(state, url) {
    state.inviteURL = url;
  },
  setInviteListURL(state, url) {
    state.inviteUrlList = url;
  },
  setErrMessage(state, value) {
    state.inviteErrMsg = value;
  },
  setSuccMessage(state, value) {
    state.inviteSuccMsg = value;
  },
  setInviteLoader(state, value) {
    state.inviteLoader = value;
  },
  [CLEAR_INVITE_DATA](state) {
    state.inviteURL = null;
    state.listOfInvites = null;
    state.inviteInfo = null;
    state.emailInvites = null;
    state.urlInvites = null;
    state.totalEmailInviteCount = 0;
    state.totalUrlInviteCount = 0;
    state.totalEmailInvites = [];
    state.totalUrlInvites = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
