import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { get } from "@/core/services/helper.service";
import JwtService from "./jwt.service";
import store from "@/store";
import router from "@/router";
import { REFRESH_TOKEN, CLEAR_STORE } from "@/store/users/auth.module";

// class CustomError extends Error {
//   constructor(data) {
//     // Pass remaining arguments (including vendor specific ones) to parent constructor
//     super(data);
//
//     // Maintains proper stack trace for where our error was thrown (only available on V8)
//     if (Error.captureStackTrace) {
//       Error.captureStackTrace(this, CustomError);
//     }
//   }
// }

//Not used but keep it
// function getCookie(name) {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2)
//     return parts
//       .pop()
//       .split(";")
//       .shift();
// }

const handleApiError = (error) => {
  // throw new CustomError(get(error, "response.data", error));
  return Promise.reject(get(error, "response.data", error));
  // return get(error, "response.data", error);
};
// const getErrors = error => {
//   throw new Error(
//     JSON.stringify({
//       status: get(error, "response.status", error.status),
//       message: get(error, "response.data.message", error.message)
//     })
//   );
// };

const _castle = (window._castle = window._castle || null);

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);

    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach((prom) => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      });

      failedQueue = [];
    };

    Vue.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;
        if (typeof error.response === "undefined") {
          let networkError = `<div>
          <p>Connection to the server was unsuccessful maybe due to one or all the following reasons:</p>
          <p>- Our servers are not reachable at this moment</p>
          <p>- Too many requests: Slow down and check again after few minutes</p>
          <p>- Unhealthy IP address or Tor or similar connections</p>
          <div>If you need more help, please do contact us</div>
         </div>`;
          store.commit("notifyErrorMessage", networkError);
          store.commit("setIsNetworkError", networkError);
          return Promise.reject({ message: networkError });
        }
        if (get(error, "response.status", "") === 471) {
          let message = get(
            error,
            "response.data.message",
            "Your membership in this team is inactive, please contact the team owners/admins for more info"
          );

          store.commit("setInactiveUserMessage", message);
        }
        if (get(error, "response.status", "") === 470) {
          store.dispatch(CLEAR_STORE);
          if (
            router &&
            router.currentRoute &&
            !["get-started", "welcome"].includes(router.currentRoute.name)
          ) {
            store.commit("notifyErrorMessage", {
              message:
                error && error.response && error.response.data
                  ? error.response.data.message
                  : "You need to be in team first",
            });
            router.push({ name: "welcome" });
          }
        }
        if (
          get(error, "response.status", "") === 401 &&
          error.config.url.indexOf("refresh-token") != -1
        ) {
          processQueue(error, null);
          isRefreshing = false;
          return Promise.reject(error);
        }

        if (
          get(error, "response.status", "") === 401 &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            return new Promise((resolve, reject) => {
              failedQueue.push({
                resolve,
                reject,
              });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = "Token " + token;
                return axios(originalRequest);
              })
              .catch((err) => {
                return Promise.reject(err);
              });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          return new Promise((resolve, reject) => {
            store
              .dispatch(REFRESH_TOKEN)
              .then((response) => {
                JwtService.saveToken(response);
                Vue.axios.defaults.headers.common["Authorization"] =
                  "Token " + response.access_token;
                originalRequest.headers["Authorization"] =
                  "Token " + response.access_token;
                processQueue(null, response.access_token);
                isRefreshing = false;
                resolve(axios(originalRequest));
              })
              .catch((err) => {
                processQueue(err, null);
                JwtService.destroyToken();
                if (!["install", "invite"].includes(router.currentRoute.name)) {
                  store.commit("setRedirectionUrl", {
                    name: router.currentRoute.name,
                    query: router.currentRoute.query,
                    params: router.currentRoute.params,
                  });
                  setTimeout(() => {
                    router.push({ name: "signin" }).catch(() => {});
                  }, 1000);
                } else {
                  store.commit("setOpenSigninProcess", true);
                }
                if (
                  router &&
                  router.currentRoute &&
                  router.currentRoute.query &&
                  router.currentRoute.query.team_id
                ) {
                  store.commit(
                    "setQueryTeamId",
                    router.currentRoute.query.team_id
                  );
                }
                isRefreshing = false;
                reject(err);
              });
          });
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common = {
      Authorization: `Token ${JwtService.getToken()}`,
      //getCookie("__cid") is used for Castle with Segment replacing window._castle("getClientId")
      "Portal-Version-Code": process.env.VUE_APP_RELEASE_CODE,
      "X-Castle-Client-Id": _castle ? _castle("getClientId") : null,
    };
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(handleApiError);
  },

  get(resource, options = {}) {
    return Vue.axios.get(`${resource}`, options).catch(handleApiError);
  },

  post(resource, params, options = {}) {
    return Vue.axios.post(`${resource}`, params, options).catch(handleApiError);
  },

  update(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(handleApiError);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(handleApiError);
  },

  delete(resource, params, options) {
    return Vue.axios
      .delete(`${resource}`, { data: params || null }, options)
      .catch(handleApiError);
  },
};

export default ApiService;
