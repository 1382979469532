import dayjs from "dayjs";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const getReleaseTime = timestamp => {
  timestamp = timestamp * 1000;
  let dt = new Date(timestamp);
  return `${dayjs(dt).format("DD MMM, YYYY")} at ${dayjs(dt).format(
    "hh:mm A"
  )}`;
};
export const currentTimestamp = () => {
  return Date.now() / 1000;
};
export const getDurationOnDays = time => {
  return Math.floor(time / 86400) + " days";
};

export const getTimelineTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return dayjs(dt).fromNow();
  } else {
    return `${dayjs(dt).format("DD MMM, YYYY")} at ${dayjs(dt).format(
      "hh:mm A"
    )}`;
  }
};
export const isEmpty = value => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};
export const get = (value, path, defaultValue) => {
  return String(path)
    .split(".")
    .reduce((acc, v) => {
      try {
        acc = acc[v];
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};
export const getInviteTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return dayjs(dt).fromNow();
  } else {
    return `${dayjs(dt).format("DD MMM, YYYY")} at ${dayjs(dt).format(
      "hh:mm A"
    )}`;
  }
};

export const getInstallTime = timestamp => {
  timestamp = timestamp * 1000;
  const dt = new Date(timestamp);
  const now = new Date().getTime();
  if (now - timestamp < 1000 * 60 * 60 * 24 * 5) {
    return dayjs(dt).fromNow();
  } else {
    return dayjs(dt).format("DD MMM, YYYY");
  }
};

export const UID = () => {
  let array = new Uint32Array(8);
  window.crypto.getRandomValues(array);
  let str = "";
  for (let i = 0; i < array.length; i++) {
    str += (i < 2 || i > 5 ? "" : "-") + array[i].toString(16).slice(-4);
  }
  return str;
};

export const formatBytes = (a, b = 2) => {
  if (!a || 0 === a || a === undefined) return "0 Bytes";
  a = a * 1000;
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1000));
  return (
    parseFloat((a / Math.pow(1000, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};
