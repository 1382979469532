import * as tus from "tus-js-client";
import store from "@/store/index";
import {
  SET_UPLOADING_PROGRESS,
  MARK_AS_UPLOADED,
  SET_FAILED,
} from "@/store/common/uploads.module";

export const upload = (
  file,
  endpoint,
  token,
  action = "express",
  type = "general"
) => {
  let tusUpload = new tus.Upload(file, {
    endpoint: process.env.VUE_APP_RELEASE_UPLOAD_API || endpoint,
    chunkSize: 5 * 1024 * 1024,
    parallelUploads: 1,
    retryDelays: [0, 3000, 5000, 10000, 20000],
    metadata: {
      token: token,
      action: action,
      filename: file.name,
      filetype: file.type,
    },
    onError: function(error) {
      let message = error.message
        .substring(
          error.message.lastIndexOf(":") + 2,
          error.message.lastIndexOf('"')
        )
        .trim();
      store.commit("notifyErrorMessage", {
        message: message || "Something went wrong, please try again later",
      });
      window.analytics.track("Release error", { message: message });
      store.commit(SET_FAILED, { type: type, error: { message } });
    },
    onProgress: function(bytesUploaded, bytesTotal) {
      let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(1);
      store.commit(SET_UPLOADING_PROGRESS, {
        type: type,
        progress: percentage > 99 ? 100 : percentage,
      });
    },
    onShouldRetry: function(err) {
      var status = err.originalResponse ? err.originalResponse.getStatus() : 0;

      window.analytics.track("Release retry", { status: status, err: err });

      // If the status is a 403, we do not want to retry.
      if (status === 403) {
        return false;
      }

      // For any other status code, tus-js-client should retry.
      return true;
    },
    onSuccess: function() {
      // store.commit("notifyUserMessage", {
      //   message: "Successfully uploaded file",
      // });
      store.commit(MARK_AS_UPLOADED, { type: type });
      return "success";
    },
  });

  function startOrResumeUpload(tusUpload) {
    // Check if there are any previous uploads to continue.
    tusUpload.findPreviousUploads().then(function(previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        // window.analytics.track("Release resumed");
        // store.commit("notifyInfoMessage", {
        //   message: "Upload resumed to a new release",
        // });
        tusUpload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      tusUpload.start();
    });
  }

  // // Add listeners for the pause and unpause button
  // var pauseButton = document.querySelector("#pauseButton");
  // var unpauseButton = document.querySelector("#unpauseButton");

  // pauseButton.addEventListener("click", function() {
  //   tusUpload.abort();
  // });

  // unpauseButton.addEventListener("click", function() {
  //   startOrResumeUpload(tusUpload);
  // });

  // Start the upload by default
  startOrResumeUpload(tusUpload);
};
