<template>
  <v-app>
    <router-view></router-view>
    <vue-snack-bar></vue-snack-bar>
    <modal
      v-model="showReleaseUpdate"
      :hide-close="
        getPortalUpdateInfo &&
        getPortalUpdateInfo.versionInfo &&
        getPortalUpdateInfo.versionInfo.force
      "
      @close="showReleaseUpdate = false"
    >
      <template #message>
        <v-row class="py-4">
          <v-col class="bold-text pb-0 dark-grey-text font-size-h3" cols="12">
            New release ready
          </v-col>
          <v-col class="font-size-h4" cols="12">
            <div class="mt-3">
              New version
              <b>{{
                `${
                  getPortalUpdateInfo && getPortalUpdateInfo.versionInfo
                    ? getPortalUpdateInfo.versionInfo.version
                    : ""
                }`
              }}</b>
              (released
              <vue-hoverable-date
                v-if="getPortalUpdateInfo && getPortalUpdateInfo.versionInfo"
                :date="getPortalUpdateInfo.versionInfo.date"
              ></vue-hoverable-date
              >)
            </div>
            <div class="my-4">
              You're using
              <b> {{ `${currentVersion.split("@")[1]}` }}</b> (released
              <vue-hoverable-date
                :date="currentReleaseTimestamp"
              ></vue-hoverable-date
              >)
            </div>
            <div class="mb-3 dark-grey-text">
              We're ready to load new version, and will bring you right back to
              this page in few seconds.
            </div>
          </v-col>
          <v-col class="text-right pb-0" cols="12">
            <v-btn
              v-if="getPortalUpdateInfo && getPortalUpdateInfo.versionInfo"
              @click="refresh"
              depressed
              color="primary"
              class="text-transform-none mx-3"
            >
              {{
                getPortalUpdateInfo.versionInfo.force
                  ? "Reload to update"
                  : "I'm Ready!"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </modal>
    <Modal v-model="showSwitchModal">
      <template #message>
        <v-row>
          <v-col class="my-4 font-size-h3 bold-text" cols="12">
            Switching teams
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col class="text-center" cols="12">Please wait...</v-col>
        </v-row>
      </template>
    </Modal>
  </v-app>
</template>



<script>
// import { OVERRIDE_LAYOUT_CONFIG } from "@/store/config.module"
import { mapGetters, mapMutations } from "vuex";
// import { SWITCH_TEAM } from "@/store/team/manageTeam.module.js";
import store from "@/store";

export default {
  name: "app",
  data() {
    return {
      tab: null,
      versionData: null,
      showReleaseUpdate: false,
    };
  },
  computed: {
    ...mapGetters({
      layoutConfig: "layoutConfig",
      getTeamId: "getTeamId",
      getTeamName: "getTeamName",
      getSwichModal: "getSwichModal",
      isSocketDisconnected: "getIsSocketDisconnected",
      getPortalUpdateInfo: "getPortalUpdateInfo",
    }),
    showSwitchModal: {
      get: function () {
        return this.getSwichModal;
      },
      set: function () {},
    },
    currentVersion() {
      return process.env.VUE_APP_RELEASE;
    },
    currentVersionCode() {
      return process.env.VUE_APP_RELEASE_CODE;
    },
    currentReleaseTimestamp() {
      return process.env.VUE_APP_RELEASE_DATE;
    },
  },
  watch: {
    getPortalUpdateInfo(val) {
      this.showReleaseUpdate = val.showReleaseUpdate;
    },
  },
  created() {},
  mounted() {
    if (store && store.getters.isAuthenticated) {
      this.getUserInfo();
    }
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    const unUsedLocalstorageItems = [
      "remember_show_install_popup",
      "express_welcom_modal",
      "remember_box",
      "express_upload_tour",
      "isUploadingReleases",
    ];
    unUsedLocalstorageItems.forEach((item) => {
      window.localStorage.removeItem(item);
    });
  },
  methods: {
    ...mapMutations({
      setIsSocketDisconnected: "setIsSocketDisconnected",
      setDataFromSocket: "setDataFromSocket",
    }),
    getUserInfo() {
      store
        .dispatch("getUserRoles")
        .then(() => {
          if (this.getTeamId) {
            this.connectToSocket();
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    refresh() {
      this.showReleaseUpdate = false;
      window.location.reload();
      this.showReleaseUpdate = false;
    },
  },
};
</script>
<style lang="scss">
@import "assets/fonts/font.css";

// Main demo style scss
@import "assets/sass/style.vue";
.test-app {
  background-color: #f6f7f9 !important;
}

.container {
  max-width: 1340px !important;
}
</style>
