import ApiService from "@/core/services/api.service";
import { get, isEmpty } from "@/core/services/helper.service.js";
import { REMOVE_APP_LIST } from "@/store/apps/app.module.js";

// actions types
export const DELETE_MEMBER = "deleteMember";
export const UPDATE_MEMBER = "updateMember";
export const MEMBERS = "members";
export const SET_MEMBERS = "members";
export const REMOVE_MEMBER = "removeMember";
export const CLEAR_APP_MEMBER = "clearAppMember";
export const GET_APP_MEMBER_LIST = "getAppMemberAutoCompleteList"
export const GET_APP_MEMBER_INITIAL_LIST = "getAppMemberAutocompleteInitialList"

const state = {
  membersList: [],
  members: [],
  totalAppMemberCount: 0,
  totalAppMemberList: [],
  membersInitialList: [],
  appMemberList: [],
  appMemberInitialList: [],
};

const getters = {
  getMemberList() {
    return state.members;
  },
  getAppMembers (state) {
    return state.appMemberList
  },
  getInitialAppMembers(state) {
    return state.appMemberInitialList
  },
  getTotalAppMemberCount(state) {
    return state.totalAppMemberCount;
  },
  getTotalAppMemberList(state) {
    return state.totalAppMemberList;
  }
};

const actions = {
  [GET_APP_MEMBER_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `core/app/${params.app_id}/member/autocomplete?search=${
          params.search
        }`
      )
        .then(({ data }) => {
          context.commit("setAppMemberAutocomplete", data.results.data);
          resolve(data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [GET_APP_MEMBER_INITIAL_LIST](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/app/${params.app_id}/member/autocomplete`)
        .then(({ data }) => {
          context.commit("setAppMemberInitialList", data.results.data);
          resolve(data.results);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [MEMBERS](context, { app_id, lastId }) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/core/app/${app_id}/members/active?lastID=${lastId || ""}`
      )
        .then(({ data }) => {
          let response = data.data;
          let members = get(response[0], "data", []);
          if (!lastId) {
            context.commit(SET_MEMBERS, { members });
            context.commit(
              "setTotalAppMemberCount",
              get(response[0], "meta.count", 0)
            );
          } else {
            context.commit("setAppMemberPaginatedData", members);
          }
          resolve(data);
        })
        .catch(error => {
          // this set no data just to stop the loaders
          reject(error);
        });
    });
  },
  [DELETE_MEMBER](context, { app_id, member_id }) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`/core/app/${app_id}/member/${member_id}`)
        .then(({ data }) => {
          context.commit(REMOVE_MEMBER, member_id);
          context.commit(REMOVE_APP_LIST, { id: app_id });
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [UPDATE_MEMBER](context, { member_id, role_id, permissions }) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/core/team/member/${member_id}`, { role_id, permissions })
        .then(({ data }) => {
          // context.commit(MEMBER_UPDATED, { member_id, permissions });
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setAppMemberAutocomplete(state, payload) {
    state.appMemberList = payload
  },
  setAppMemberInitialList(state, payload) {
    state.appMemberInitialList = payload
  },
  [SET_MEMBERS](state, { members }) {
    if (!isEmpty(members)) {
      state.members = members || [];
      state.totalAppMemberList = members || [];
    }
    state.membersLoading = false;
  },
  [REMOVE_MEMBER](state, member_id) {
    if (state.members && state.members.length) {
      state.members = state.members.filter(member => member.id != member_id);
      state.totalAppMemberList = state.totalAppMemberList.filter(
        member => member.id != member_id
      );
    }
  },

  setTotalAppMemberCount(state, payload) {
    state.totalAppMemberCount = payload;
  },
  setAppMemberPaginatedData(state, data) {
    let appMember = state.totalAppMemberList.length
      ? state.totalAppMemberList
      : state.members;
    appMember = [...appMember, ...data];
    state.members = [...data];
    state.totalAppMemberList = [...appMember];
  },
  setPreviousAppMemberData(state, data) {
    state.members = [...data];
  },
  removeAppMember(state, memberId) {
    state.totalAppMemberList = state.totalAppMemberList.filter(
      member => member.id !== memberId
    );
    state.members = state.members.filter(member => member.id !== memberId);
    state.totalAppMemberCount = state.totalAppMemberCount - 1;
  },
  // when trying to get apps
  setMemberAutocomplete(state, payload) {
    state.membersList = payload;
  },
  setMemberInitialList(state, payload) {
    state.membersInitialList = payload;
  },
  [CLEAR_APP_MEMBER](state) {
    state.membersList = [];
    state.members = [];
    state.totalAppMemberCount = 0;
    state.totalAppMemberList = [];
    state.membersInitialList = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
