// action types
export const NEW_UPLOAD = "new";
export const UPLOADING = "uploading";
export const UPLOADED = "uploaded";
export const UPLOAD_FAILED = "failed";
export const CLEAR_UPLOADS = "clearUploads";
export const CANCEL_UPLOAD = "cancelUpload";

// mutation types
export const NEW_UPLOAD_START = "newUpload";
export const SET_UPLOADING_PROGRESS = "setUploading";
export const MARK_AS_UPLOADED = "setUploaded";
export const SET_FAILED = "failed";
export const CLEAR_UPLOADS_DATA = "clearUploadsData";

const state = {
  uploads: [],
  iosProgress: null,
  androidProgress: null
};

const getters = {
  getUploads(state) {
    return state.uploads;
  },
  getIOSProgress(state) {
    return state.iosProgress;
  },
  getAndroidProgress() {
    return state.androidProgress;
  }
};

const actions = {
  [NEW_UPLOAD](context, release) {
    context.commit(NEW_UPLOAD_START, release);
  },
  [UPLOADING](context, { id, progress }) {
    context.commit(
      "notifyUserMessage",
      "Upload is still in progress, please wait..."
    );
    context.commit(SET_UPLOADING_PROGRESS, { id, progress });
  },
  [UPLOADED](context, id) {
    context.commit(MARK_AS_UPLOADED, id);
  },
  [UPLOAD_FAILED](context, params) {
    context.commit(SET_FAILED, params);
  },
  [CLEAR_UPLOADS](context) {
    context.commit(CLEAR_UPLOADS_DATA);
  },
  [CANCEL_UPLOAD](context, id) {
    context.commit(CANCEL_UPLOAD, id);
  }
};

const mutations = {
  resetUpload(state) {
    state.androidProgress = null;
    state.iosProgress = null;
    state.uploads = [];
  },
  [NEW_UPLOAD_START](state, release) {
    state.androidProgress = null;
    state.iosProgress = null;
    if (release.type === "android") {
      state.androidProgress = { ...release, status: "new" };
    } else if (release.type === "ios") {
      state.iosProgress = { ...release, status: "new" };
    }
  },
  [SET_UPLOADING_PROGRESS](state, payload) {
    if (payload.type === "android") {
      state.androidProgress = { ...payload, status: "uploading" };
    } else if (payload.type === "ios") {
      state.iosProgress = { ...payload, status: "uploading" };
    }
  },
  [MARK_AS_UPLOADED](state, payload) {
    if (payload.type === "android") {
      state.androidProgress = { ...payload, status: "uploaded" };
    } else if (payload.type === "ios") {
      state.iosProgress = { ...payload, status: "uploaded" };
    }
  },
  [SET_FAILED](state, payload) {
    if (payload.type === "android") {
      state.androidProgress = { ...payload, status: "failed" };
    } else {
      state.iosProgress = { ...payload, status: "failed" };
    }
  },
  [CLEAR_UPLOADS_DATA](state) {
    state.uploads = [];
  },
  [CANCEL_UPLOAD](state, id) {
    state.uploads = state.uploads.filter(upload => upload.id != id);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
