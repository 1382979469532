import Vue from "vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import StorageService from "@/core/services/localstorage.service";
// import $socket from "@/store/Common/socket-instance";

import { CLEAR_APP } from "../apps/app.module";
import { CLEAR_APPS } from "../apps/apps.module";
import { CLEAR_CREATE_APP } from "@/store/apps/createApp.module";
import { CLEAR_DASHBOARD } from "@/store/users/dashboard.module";
import { CLEAR_UPLOADS } from "@/store/common/uploads.module";
import { CLEAR_APP_AUTOCOMPLETE } from "@/store/common/util.module";
import { CLEAR_TEAM } from "@/store/team/manageTeam.module";
import { SET_APP_CONSTANTS } from "@/store/common/util.module.js";
import * as Sentry from "@sentry/vue";
import { CLEAR_APP_MEMBER } from "@/store/apps/appmember.module.js";
import { CLEAR_NOTIFICATION_DATA } from "@/store/users/notifications.module.js";
import { CLEAR_STORAGE } from "@/store/team/storage.module.js";
import { CLEAR_WHATS_NEW_LIST } from "@/store/users/whatsNew.module.js";
import { CLEAR_RELEASE_DATA } from "@/store/releases/releases.module.js";
import { CLEAR_TIMELINE_DATA } from "@/store/apps/timeline.module.js";
import { CLEAR_TOKEN_LIST } from "@/store/users/settings.module.js";
import { CLEAR_INVITE_DATA } from "@/store/team/invite.module.js";
import { CLEAR_TEAM_MEMBER_DATA } from "@/store/team/members.module.js";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const CLEAR_STORE = "clearStore";
export const SIGNIN = "signin";
export const SIGNIN_WITH_TOKEN = "signinWithToken";
export const SIGNOUT = "signout";
export const SIGNUP = "signup";
export const FORGOT_PASSWORD = "forgotPassword";
export const RESET_PASSWORD = "resetPassword";
export const REFRESH_TOKEN = "refreshToken";
export const PURGE_AUTH_DATA = "purgeAuthData";
export const VERIFY_ACTIVATE = "verifyActivate";
export const VERIFY_CODE = "verifyCode";
export const RESEND_TOKEN = "reSendToken";
export const ACTIVATE_ACCOUNT = "activateAccount";
export const VERIFY_RESET_PASSWORD_TOKEN = "verifyResetPasswordToken";
export const UPDATE_USER_TOKEN = "updateUserToken";

export const UPDATE_PROFILE = "updateProfile";
export const UPDATE_PROFILE_IMAGE = "updateProfileImage";
export const UPDATE_INFO = "updateInfo";
export const DELETE_PROFILE_IMAGE = "deleteProfileImages";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const UPDATE_ROLES = "setUserRoles";
export const SET_ERROR = "setError";
export const GET_USER_ROLES = "getUserRoles";

const user = StorageService.getItem("user");
var _hsq = (window._hsq = window._hsq || []);
const _castle = (window._castle = window._castle || null);

const state = {
  errors: null,
  user: StorageService.getItem("user"),
  userRoles: {},
  navigateTo: "",
  redirectionUrl: {},
  inactiveUserMessage: "",
  authMessage: {
    message: "",
    type: "error",
  },
  activateErrorMessage: "",
  queryTeamId: "",
  infoLoaded: false,
  portalUpdateInfo: {
    showReleaseUpdate: false,
    versionInfo: null,
  },
  isAuthenticated: !!JwtService.getToken(),
};

// function gtag_report_signup_conversion(url) {
//   var callback = function() {

//     if (typeof url != "undefined") {
//       window.location = url;
//     }
//   };
//   window.gtag("event", "conversion", {
//     send_to: "AW-448987833/-wkpCJu_i_4BELmFjNYB",
//     event_callback: callback,
//   });
//   return false;
// }

if (user) {
  // /** START SOCKET */
  // $socket
  //   .emit("authenticate", {
  //     token: JwtService.getToken(),
  //   })
  //   .on("authenticated", () => {
  //   })
  //   .on("status", (res) => {
  //   })
  //   .on("disconnect", (res) => {
  //   });

  // Scroll page to top on every route change
  setTimeout(() => {
    /** Firebase */
    Vue.prototype.$analytics.setUserId(user.id);

    /** Castle */

    if (_castle) {
      _castle("identify", user.id);
      _castle("trackPageview");
    }

    /** Hubspot */
    _hsq.push([
      "identify",
      {
        id: user.id,
        email: user.email,
        firstname: user.name,
      },
    ]);

    /** Smartlook */
    // window.smartlook("consentAPI", "Here goes consent text from your website.");
    // window.smartlook("identify", user.id);

    /** Hotjar */
    // window.hj("identify", user.id, {});

    /** Sentry */
    Sentry.setUser({ id: user.id });

    /** Segment */
    window.analytics.identify(user.id, {
      email: user.email,
      name: user.name,
    });
  }, 100);
}

const getters = {
  getPortalUpdateInfo(state) {
    return state.portalUpdateInfo;
  },
  getInactiveUserMessage(state) {
    return state.inactiveUserMessage;
  },
  currentUser(state) {
    return state.user;
  },
  getQueryTeamId(state) {
    return state.queryTeamId;
  },
  getNavigateTo(state) {
    return state.navigateTo;
  },
  getRedirectionUrl(state) {
    return state.redirectionUrl;
  },
  getWhatsNewLastReadId(state) {
    return state.userRoles.last_whats_new;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getAuthMessage(state) {
    return state.authMessage;
  },
  getActivateErrorMessage(state) {
    return state.activateErrorMessage;
  },
  getNotificationsSetting(state) {
    return state.userRoles && state.userRoles.profile
      ? state.userRoles.profile.notification_settings
      : null;
  },
  userRoles(state) {
    return state.userRoles;
  },
  isInfoLoaded(state) {
    return state.infoLoaded;
  },
  getTeamId(state) {
    return state.userRoles && state.userRoles.team
      ? state.userRoles.team.id
      : StorageService.getItem("current_team_id");
  },
  getIsUserDisabled(state) {
    return state.userRoles && state.userRoles.team
      ? state.userRoles.team.member_status !== 1
      : false;
  },
  getTeamName(state) {
    return state.userRoles && state.userRoles.team
      ? state.userRoles.team.name
      : "";
  },
  getCurrentPlanName(state) {
    return state.userRoles && state.userRoles.team_plan
      ? { ...state.userRoles.team_plan.usage }
      : {};
  },
  getTeamPlanUsage(state) {
    return state.userRoles && state.userRoles.team_plan
      ? { ...state.userRoles.team_plan.usage }
      : {};
  },
  getTeamPlanLimits(state) {
    return state.userRoles &&
      state.userRoles.team_plan &&
      state.userRoles.team_plan.usage
      ? { ...state.userRoles.team_plan.usage.limits }
      : {};
  },
  getTeamTrial(state) {
    return state.userRoles &&
      state.userRoles.team_plan &&
      state.userRoles.team_plan.trial
      ? { ...state.userRoles.team_plan.trial }
      : {};
  },
  userPermissions(state) {
    return state.userRoles && state.userRoles.permissions
      ? { ...state.userRoles.permissions }
      : {};
  },
  getAssets(state) {
    return state.userRoles.assets;
  },
  userNotificationsStats(state) {
    return state.userRoles && state.userRoles.notifications
      ? { ...state.userRoles.notifications }
      : {};
  },
};

const actions = {
  [GET_USER_ROLES](context) {
    context.commit("setRolesInfoLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("/core/info")
        .then(async ({ data }) => {
          resolve(data);
          context.commit("setRolesInfoLoader", false);
          StorageService.setItem(
            "current_team_id",
            data && data.team ? data.team.id : ""
          );
          context.commit(UPDATE_ROLES, data);
          context.commit(SET_APP_CONSTANTS, { assets: data.assets });
          context.commit(UPDATE_INFO, data.profile);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
          context.commit("setRolesInfoLoader", false);
        });
    });
  },
  [UPDATE_PROFILE](context, dataToPost) {
    return new Promise((resolve, reject) => {
      ApiService.put("/core/profile", dataToPost)
        .then(async ({ data }) => {
          resolve(data);
          context.commit(UPDATE_INFO, dataToPost);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [DELETE_PROFILE_IMAGE](context) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/core/image")
        .then(async (response) => {
          const data = {
            image: "noImage",
          };
          context.commit(UPDATE_INFO, data);
          resolve(response);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [UPDATE_PROFILE_IMAGE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/image", data)
        .then(async ({ data }) => {
          context.commit(UPDATE_INFO, data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SIGNIN](context, credentials) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("/core/signin", credentials)
        .then(async ({ data }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SIGNIN_WITH_TOKEN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/social-auth", credentials)
        .then(async ({ data }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [SIGNOUT](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/core/signout")
        .then(({ data }) => {
          context.commit(PURGE_AUTH);
          context.dispatch(CLEAR_STORE);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [CLEAR_STORE](context) {
    context.commit("setInactiveUserMessage", "");
    context.commit("clearPermission");
    context.dispatch(CLEAR_APP);
    context.dispatch(CLEAR_APPS);
    context.dispatch(CLEAR_CREATE_APP);
    context.dispatch(CLEAR_DASHBOARD);
    context.dispatch(CLEAR_UPLOADS);
    context.dispatch(CLEAR_APP_AUTOCOMPLETE);
    context.commit(CLEAR_TEAM);
    context.commit("clearInvitesList");
    context.commit(CLEAR_APP_MEMBER);
    context.commit(CLEAR_NOTIFICATION_DATA);
    context.commit(CLEAR_STORAGE);
    context.commit(CLEAR_WHATS_NEW_LIST);
    context.commit(CLEAR_RELEASE_DATA);
    context.commit(CLEAR_TIMELINE_DATA);
    context.commit(CLEAR_TOKEN_LIST);
    context.commit(CLEAR_INVITE_DATA);
    context.commit(CLEAR_TEAM_MEMBER_DATA);
    context.commit("clearInvitesList", null);
  },
  [SIGNUP](context, user) {
    ApiService.setHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("/core/signup", user)
        .then(({ data }) => {
          // gtag_report_signup_conversion();
          Vue.prototype.$analytics.logEvent("signup");
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [FORGOT_PASSWORD](context, email) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/forgot-password", email)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [RESET_PASSWORD](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/reset-password", credentials)
        .then(async ({ data }) => {
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          context.commit(SET_ERROR, error.message);
          reject(error);
        });
    });
  },
  [REFRESH_TOKEN]() {
    return new Promise((resolve, reject) => {
      ApiService.post("/core/refresh-token", {
        refresh_token: JwtService.getRefreshToken(),
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_ACTIVATE](context, token) {
    context.dispatch(CLEAR_STORE);
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/activate/${token}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/verify-code`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [RESEND_TOKEN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/resend-token`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [ACTIVATE_ACCOUNT](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.post(`/core/activate`, { token })
        .then(async ({ data }) => {
          await context.dispatch(CLEAR_STORE);
          await context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_RESET_PASSWORD_TOKEN](context, token) {
    return new Promise((resolve, reject) => {
      ApiService.get(`/core/reset-password/${token}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [PURGE_AUTH_DATA](context) {
    context.commit(PURGE_AUTH);
  },
  [UPDATE_USER_TOKEN](context, token) {
    let user = StorageService.getItem("user");
    user.token = token;
    StorageService.setItem("user", user);
    context.commit("updateUserToken", token);
    JwtService.saveToken(token);
    ApiService.setHeader();
  },
};

const mutations = {
  setInactiveUserMessage(state, payload) {
    state.inactiveUserMessage = payload;
  },
  setPortalUpdateInfo(state, payload) {
    state.portalUpdateInfo = payload || null;
  },
  setRedirectionUrl(state, payload) {
    state.redirectionUrl = payload || {};
  },
  setQueryTeamId(state, payload) {
    state.queryTeamId = payload;
  },
  setTeamName(state, payload) {
    let info = state.userRoles;
    if (info.team && info.team.name) {
      info.team.name = payload;
    }
    state.userRoles = info;
  },
  setNavigateTo(state, payload) {
    state.navigateTo = payload;
  },
  updateUserToken(state, payload) {
    let user = state.user;
    user.token = payload;
    state.user = user;
  },
  setAuthMessage(state, payload) {
    state.authMessage = payload;
  },
  setActivateErrorMessage(state, payload) {
    state.activateErrorMessage = payload || "";
  },
  setRolesInfoLoader(state, data) {
    state.infoLoaded = data;
  },
  updateNotificationsSetting(state, data) {
    let userData = state.userRoles;
    if (userData.profile) {
      userData.profile.notification_settings = data;
    }
    state.userRoles = userData;
  },
  updateNotification(state, data) {
    let detail = state.userRoles;
    detail.notifications = {
      alerts: data.alerts,
      invites: data.invites,
    };
    state.userRoles = { ...detail };
  },
  updateUserPermission(state, data) {
    let detail = state.userRoles;
    detail.permissions = { ...data };
    state.userRoles = { ...detail };
  },
  updateTeamId(state, teamId) {
    let detail = state.userRoles;
    detail.team_id = teamId;
    state.userRoles = { ...detail };
  },
  updateUserUsages(state, data) {
    let detail = state.userRoles;
    detail.team_plan = { ...data };
    state.userRoles = { ...detail };
  },
  clearPermission(state) {
    state.userRoles = {};
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [UPDATE_ROLES](state, data) {
    state.userRoles = data;
  },
  [UPDATE_INFO](state, data) {
    let user = state.user;
    if (data.image) {
      if (data.image === "noImage") {
        user.image = "";
      } else {
        user.image = data.image;
      }
    }
    if (data.name) {
      user.name = data.name;
      user.email = data.email;
    }
    state.user = user;
    StorageService.setItem("user", user);
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = null;
    StorageService.setItem("user", user);
    JwtService.saveToken(state.user.token);
    ApiService.setHeader();

    /** Firebase */
    Vue.prototype.$analytics.setUserId(state.user.id);
    Vue.prototype.$analytics.logEvent("signin");

    /** Gtag for Google */
    // Vue.prototype.$gtag.set({ user_id: state.user.id });

    /** Hubspot */
    _hsq.push([
      "identify",
      {
        id: user.id,
        email: user.email,
        firstname: user.name,
      },
    ]);
    _hsq.push(["trackPageView"]);

    // if (window.HubSpotConversations) {
    //   window.HubSpotConversations.widget.refresh();
    // }

    /** Castle */
    if (_castle) {
      _castle("identify", user.id);
      _castle("trackPageview");
    }

    /** Smartlook */
    // window.smartlook("identify", "");

    /** Hotjar */
    // window.hj("identify", user.id, {});

    /** Sentry */
    Sentry.setUser({ username: user.id });

    /** Segment */
    window.analytics.identify(user.id, {
      email: user.email,
      name: user.name,
    });
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = null;
    StorageService.removeItem("user");
    StorageService.removeItem("roles");
    JwtService.destroyToken();

    /** Firebase */
    Vue.prototype.$analytics.setUserId(null);
    Vue.prototype.$analytics.logEvent("signout");

    /** Gtag for Google */
    // Vue.prototype.$gtag.set({ user_id: null });

    /** Castle */
    if (_castle) {
      _castle("reset");
    }

    /** Hubspot */
    _hsq.push(["identify", {}]);
    // if (window.HubSpotConversations) {
    //   window.HubSpotConversations.widget.refresh();
    // }

    /** Sentry */
    Sentry.configureScope((scope) => scope.setUser(null));

    /** Segment */
    window.analytics.reset();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
