import { mapGetters, mapMutations } from "vuex";
import socket from "@/store/common/socket-instance";
import Vue from "vue";

export default {
  computed: {
    ...mapGetters({
      assets: "getAssets",
      getTeamId: "getTeamId",
      getIsUserDisabled: "getIsUserDisabled",
    }),

    /**
     * @returns
     *
     * android: Android device.
     * ios: iOS device.
     * ios-desktop-mode: They need to change to mobile mode.
     * unknown: They need to open this page from Android or iOS device.
     *
     */
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "android";
      } else if (
        (/iPad|iPhone|iPod/.test(userAgent) ||
          (navigator.platform === "MacIntel" &&
            navigator.maxTouchPoints > 1)) &&
        !window.MSStream
      ) {
        return "ios";
      }

      return "unknown";
    },
  },
  methods: {
    ...mapMutations({
      notifyUserMessage: "notifyUserMessage",
      notifyInfoMessage: "notifyInfoMessage",
      notifyErrorMessage: "notifyErrorMessage",
      cancelSnackbar: "cancelSnackbar",
      setIsSocketDisconnected: "setIsSocketDisconnected",
      setPortalUpdateInfo: "setPortalUpdateInfo",
      setDataFromSocket: "setDataFromSocket",
    }),
    connectToSocket() {
      const socketInstance = socket.connect();

      socketInstance.subscribe({
        key: process.env.VUE_APP_EMMITTER_PORTAL_UPDATE_KEY,
        channel: "main/portal/update",
      });

      socketInstance.subscribe({
        key: process.env.VUE_APP_EMMITTER_RELEASE_KEY,
        channel: `main/team/${this.getTeamId}/release/status`,
      });

      this.reconnecting = false;
      socketInstance.on("disconnect", () => {
        this.setIsSocketDisconnected(true);
      });
      socket.socket().on("message", (msg) => {
        let data = msg.asObject();
        if (msg.channel === `main/team/${this.getTeamId}/release/status/`) {
          this.setDataFromSocket(data);
        } else if (msg.channel === `main/portal/update/`) {
          if (
            parseInt(data.version_code) >
            parseInt(process.env.VUE_APP_RELEASE_CODE)
          ) {
            this.setPortalUpdateInfo({
              showReleaseUpdate: true,
              versionInfo: data,
            });
          }
        }
      });
      this.setIsSocketDisconnected(false);
    },
    getErrorMessage(err) {
      if (err && err.message) {
        return JSON.parse(err.message);
      } else {
        return null;
      }
    },
    getAppImage(image, id) {
      if (image || id) {
        if (id) {
          return image || "/media/defaults/icon-120.png";
          // return `${this.assets ? this.assets.url : ''}${this.assets ? this.assets.app : ''}${id}/image/${image}`;
        } else {
          return image.image || "/media/defaults/icon-120.png";
          // return `${this.assets.url}${this.assets ? this.assets.app : ''}${image.id}/image/${image.image}`;
        }
      }
    },
    getStorageImg(name) {
      return "/media/svg/storage/" + name;
    },
    getProfileImage(image) {
      return image;
      // return `${this.assets ? this.assets.url : ""}${
      //   this.assets ? this.assets.user : ""
      // }image/${image}`;
    },
    replaceDefaultUserImage(e) {
      e.target.src = "/media/defaults/member.jpg";
    },
    replaceDefaultAppImage(e) {
      e.target.src = "/media/defaults/icon-100.png";
    },
    onContactus() {
      if (window && window.HubSpotConversations) {
        window.HubSpotConversations.widget.open();
        Vue.prototype.$analytics.logEvent("contact us modal", {
          status: "success",
          action: "contact_us",
        });
      } else {
        window.open("https://testapp.io/contact", "_blank");
        Vue.prototype.$analytics.logEvent("redirect to landing page", {
          status: "success",
          action: "contact_us",
        });
      }
    },
    async copy(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.notifyUserMessage({
          message: "Copied successfully",
          timeout: true,
        });
      } catch (error) {
        this.notifyErrorMessage({
          message: "An error occurred while copying",
          timeout: true,
        });
      }
    },
  },
};
