import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
// import 'vuetify/dist/vuetify.min.css'
import "@/assets/sass/vuetify-variables.scss";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#396BDB",
        secondary: "#e8ecfa",
        accent: "#58B9F0",
        error: "#e75146",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
        darkOrange: "#A47125",
        lightRed: "#FF9C9C",
        grey: "#dadae1",
        darkGrey: "#464e5f"
      }
    }
  }
});
