import ApiService from "@/core/services/api.service.js";
import StorageService from "@/core/services/localstorage.service.js";
import { GET_RELEASE_INFO } from "@/store/releases/createRelease.module.js";

export const GET_EXPRESS_LIST = "getExpressReleases";
export const REMOVE_EXPRESS_RELEASE = "removeExpressRelease";
export const GET_EXPRESS_RELEASE_TOKEN = "getExpressReleaseToken";

const state = {
  expressRelease: [],
  totalExpressCount: 0,
  totalExpressList: [],
  expressHeaderOption: null,
  expressToken: "",
  expressOptions: {
    headers: {
      common: {
        "Express-Token": StorageService.getItem("express_token")
      }
    }
  }
};

const getters = {
  getExpressRelease(state) {
    return state.expressRelease;
  },
  expressReleaseToken() {
    return (
      state.expressToken || StorageService.getItem("express_token") || null
    );
  },
  getTotalExpressApp(state) {
    return state.totalExpressCount;
  },
  getTotalExpressList(state) {
    return state.totalExpressList;
  }
};

const actions = {
  [GET_EXPRESS_RELEASE_TOKEN]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`/portal/express/start`)
        .then(({ data }) => {
          StorageService.setItem("express_token", data.token || null);
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_EXPRESS_LIST](context, lastId) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `/portal/express/releases?lastID=${lastId || ""}`,
        state.expressHeaderOption || state.expressOptions
      )
        .then(({ data }) => {
          if (data.results[0]) {
            if (lastId) {
              context.commit(
                "setExpressPaginationReleases",
                data.results[0].data
              );
            } else {
              context.commit("setExpressRelease", data.results[0].data);
              context.commit(
                "setTotalExpressApp",
                data.results[0] && data.results[0].meta
                  ? data.results[0].meta.total
                  : 0
              );
            }
          }
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REMOVE_EXPRESS_RELEASE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `/portal/express/release/${params.id}`,
        state.expressHeaderOption || state.expressOptions
      )
        .then(async ({ data }) => {
          context.commit("removeExpressRelease", params);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_RELEASE_INFO](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get(`portal/install/release/${params.releaseId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
const mutations = {
  setExpressHeaderOption(state, payload) {
    state.expressToken = payload;
    state.expressHeaderOption = {
      headers: {
        common: {
          "Express-Token": payload
        }
      }
    };
  },
  setExpressRelease(state, payload) {
    state.expressRelease = payload;
    state.totalExpressList = payload;
  },
  setExpressPaginationReleases(state, payload) {
    let expressReleases = state.totalExpressList.length
      ? state.totalExpressList
      : state.expressRelease;
    expressReleases = [...expressReleases, ...payload];
    state.totalExpressList = [...expressReleases];
    state.expressRelease = payload;
  },
  setCurrentExpressRelease(state, payload) {
    state.expressRelease = payload;
  },
  setTotalExpressApp(state, payload) {
    state.totalExpressCount = payload;
  },
  deductTotalCount(state) {
    state.totalExpressCount = state.totalExpressCount
      ? state.totalExpressCount - 1
      : 0;
  },
  removeExpressRelease(state, payload) {
    let list = state.expressRelease;
    state.expressRelease = list.filter(x => x.id !== payload.id);
    state.totalExpressList = state.totalExpressList.filter(
      x => x.id !== payload.id
    );
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
