import ApiService from "@/core/services/api.service";
export const SEND_MESSAGE = "sendMessage";
const state = {};
const getters = {};
const actions = {
  [SEND_MESSAGE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/contact", payload)
        .then(({ data }) => resolve(data))
        .catch(error => reject(error));
    });
  }
};
const mutations = {};
export default {
  state,
  getters,
  actions,
  mutations
};
