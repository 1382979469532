import { isEmpty, get } from "@/core/services/helper.service";
import ApiService from "@/core/services/api.service";
import router from "@/router";

// action types
export const DASHBOARD = "dashboard";
export const CLEAR_DASHBOARD = "clearDashboard";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const SEND_FEEDBACK = "sendFeedback";

// mutation types
export const SET_DASHBOARD = "dashbaord";
export const CLEAR_DASHBOARD_DATA = "clearDashboardData";

const state = {
  usage: {},
  currentPlanUsage: 0,
  apps: [],
  releases: [],
  feedback: [],
  team_members: [],
  total_releases: 0,
  total_feedback: 0,
  total_team_members: 0,
  total_apps: 0,
  inviteDrawer1: false,
  inviteDrawer2: false,
  dashboardLoader: false,
};

const getters = {
  getDashboardApps(state) {
    return state.apps;
  },
  getDashboardReleases(state) {
    return state.releases ? state.releases.slice(0, 4) : [];
  },
  getDashboardFeedback(state) {
    return state.feedback;
  },
  getDashboardTeamMembers(state) {
    return state.team_members;
  },
  getPlanUsage(state) {
    return state.usage;
  },
};

const actions = {
  [GET_NOTIFICATIONS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("core/notifications")
        .then(({ data }) => {
          let invite = [];
          let alert = [];
          let system = [];
          data.data.map((v) =>
            v.data.map((v) => {
              if (v.action == "invite") {
                invite.push(v);
              }
              if (v.action == "alert") {
                alert.push(v);
              }
              if (v.action == "system") {
                system.push(v);
              }
            })
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [SEND_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/feedback", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [DASHBOARD](context) {
    context.commit("setDashboardLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("/portal/dashboard")
        .then(({ data }) => {
          let apps = get(data, "data.apps.data", []);
          let releases = get(data, "data.releases.data", []);
          let feedback = get(data, "data.feedback.data", []);
          let team_members = get(data, "data.team_members.data", []);
          let total_apps = get(data, "data.apps.meta.total", 0);
          let total_releases = get(data, "data.releases.meta.total", 0);
          let total_feedback = get(data, "data.feedback.meta.total", 0);
          let total_team_members = get(data, "data.team_members.meta.total", 0);
          context.commit("setTotalAppCount", total_apps);

          let usage = get(data, "usage", {});
          let currentPlanUsage = 0;
          if (
            !isEmpty(usage) &&
            get(usage, "team", false) &&
            get(usage, "plan", false)
          ) {
            currentPlanUsage = (
              ((usage.team.app + usage.team.release) /
                (usage.plan.app + usage.plan.release)) *
              100
            ).toFixed(0);
          }

          context.commit(SET_DASHBOARD, {
            usage,
            currentPlanUsage,
            apps,
            releases,
            feedback,
            team_members,
            total_apps,
            total_releases,
            total_feedback,
            total_team_members,
          });
          if (!data.team) {
            router.push("/welcome");
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          context.commit("setDashboardLoader", false);
        });
    });
  },
  [CLEAR_DASHBOARD](context) {
    context.commit(CLEAR_DASHBOARD_DATA);
  },
};

const mutations = {
  setApps(state, apps) {
    state.apps.push(apps);
  },
  [SET_DASHBOARD](
    state,
    {
      usage,
      currentPlanUsage,
      apps,
      releases,
      feedback,
      team_members,
      total_apps,
      total_releases,
      total_feedback,
      total_team_members,
    }
  ) {
    if (state) {
      state.usage = usage || {};
      state.currentPlanUsage = currentPlanUsage || 0;
      state.apps = apps || [];
      state.releases = releases || [];
      state.feedback = feedback || [];
      state.team_members = team_members || [];
      state.total_apps = total_apps || 0;
      state.total_releases = total_releases || 0;
      state.total_feedback = total_feedback || 0;
      state.total_team_members = total_team_members || 0;
      state.dashboardLoader = false;
    }
  },
  setDashboardLoader(state, data) {
    state.dashboardLoader = data || false;
  },
  [CLEAR_DASHBOARD_DATA](state) {
    state.apps = [];
    state.releases = [];
    state.feedback = [];
    state.team_members = [];
    state.total_releases = 0;
    state.total_feedback = 0;
    state.total_team_members = 0;
    state.total_apps = 0;
  },
  setInviteDrawer1(state, v) {
    state.inviteDrawer1 = v;
  },
  setInviteDrawer2(state, v) {
    state.inviteDrawer2 = v;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
