import ApiService from "@/core/services/api.service";
import StorageService from "@/core/services/localstorage.service";

// action types
export const SET_APP_CONSTANTS = "setAppConstants";
export const APP_AUTOCOMPLETE = "appAutoComplete";
export const CLEAR_APP_AUTOCOMPLETE = "clearAppAutoComplete";

const state = {
  constants: StorageService.getItem("constants"),
  apps: []
};

const getters = {
  getConstants(state) {
    return state.constants;
  }
  // getAppsAutocomplete(state) {
  //     return state.apps;
  // }
};

const actions = {
  [APP_AUTOCOMPLETE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("portal/apps/dropdown")
        .then(async ({ data }) => {
          resolve(data);
          context.commit(APP_AUTOCOMPLETE, data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CLEAR_APP_AUTOCOMPLETE](context) {
    context.commit(CLEAR_APP_AUTOCOMPLETE);
  }
};

const mutations = {
  [SET_APP_CONSTANTS](state, constants) {
    state.constants = constants;
  },
  [APP_AUTOCOMPLETE](state, apps) {
    state.apps = apps;
  },
  [CLEAR_APP_AUTOCOMPLETE]() {
    state.apps = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
