// import { get } from "lodash";
import ApiService from "@/core/services/api.service";

// action types
export const GET_STORAGE = "getStorage";
export const GET_PROVIDERS = "getProviders";
export const CREATE_STORAGE = "createStorage";
export const DELETE_STORAGE = "deleteStorage";
export const UPDATE_STORAGE = "updateStorage";

// mutation types
export const CLEAR_STORAGE = "clearStorageInfo";

const state = {
  // init the members
  storageList: null,
  externalStorageList: [],
  providersList: []
};

const getters = {
  getStorageStats(state) {
    return state.storageList ? state.storageList.usage : null;
  },
  getExternalStorageList(state) {
    return state.externalStorageList ? state.externalStorageList : [];
  },
  getProvidersList(state) {
    return state.providersList;
  }
};

const actions = {
  [GET_STORAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("core/team/storage", params)
        .then(({ data }) => {
          // ? // this for add storage to existing data
          // context.commit("addStorageToList", data.results)
          // : // this for fetching the all the data
          context.commit("setStorageList", data.results);
          resolve(data.results);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_STORAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post("core/team/storage", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [UPDATE_STORAGE](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.put(`core/team/storage/${params.id}`, params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [DELETE_STORAGE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`core/team/storage/${params.id}`)
        .then(({ data }) => {
          commit("removeExternalStorage", params.id);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_PROVIDERS](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.get("core/team/storage/providers", params)
        .then(({ data }) => {
          context.commit("setProvidersList", data.data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  setProvidersList(state, payload) {
    state.providersList = payload;
  },
  setStorageList(state, payload) {
    state.storageList = payload;
    state.externalStorageList = payload.external;
  },
  appendExternalStorage(state, payload) {
    state.externalStorageList.push(payload);
  },
  removeExternalStorage(state, id) {
    state.externalStorageList = state.externalStorageList.filter(
      x => x.id !== id
    );
  },
  [CLEAR_STORAGE](state) {
    state.storageList = null;
    state.externalStorageList = [];
    state.providersList = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
