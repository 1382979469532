import ApiService from "@/core/services/api.service";
import StorageService from "@/core/services/localstorage.service";

// action types
export const FETCH_WHATS_NEW_LIST = "fetchRecentUpdateList";
export const POST_WHATS_NEW_FEEDBACK = "postRecentUpdateFeedback";
// mutation types
export const SET_WHATS_NEW_LIST = "setRecentUpdateList";
export const CLEAR_WHATS_NEW_LIST = "clearWhatsNewList";

const state = {
  whatsNewList: []
};

const getters = {
  getWhatsNewList(state) {
    return state.whatsNewList;
  }
};

const actions = {
  [FETCH_WHATS_NEW_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get(`core/whats-new`)
        .then(({ data }) => {
          context.commit(SET_WHATS_NEW_LIST, data.data[0].data);
          if (
            data &&
            data.data[0] &&
            data.data[0].data &&
            data.data[0].data[0]
          ) {
            StorageService.setItem(
              "last_whats_new_read_id",
              data.data[0].data[0].id
            );
          }
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [POST_WHATS_NEW_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`core/whats-new/${payload.id}/feedback`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_WHATS_NEW_LIST](state, payload) {
    state.whatsNewList = payload;
  },
  [CLEAR_WHATS_NEW_LIST](state) {
    state.whatsNewList = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
