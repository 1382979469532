import ApiService from "@/core/services/api.service";
import store from "@/store/index";
import { upload } from "@/mixins/upload";
import { NEW_UPLOAD_START, SET_FAILED } from "@/store/common/uploads.module";
import StorageService from "@/core/services/localstorage.service.js";
// action types
export const RELEASE = "release";
export const PRE_RELEASE = "preRelease";
export const RELEASE_UPLOAD = "releaseUpload";
export const GET_RELEASE_INFO = "getAppReleaseInfo";
export const GET_RELEASE_INSTALL_LIST = "getReleaseInstallList";
export const GET_APP_INSTALL_LIST = "getAppInstallList";
export const GENERATE_EXPORT_INSTALL_LINK = "generateExportInstallLink";

// mutation types

const state = {
  apkFileInfo: {},
  ipaFileInfo: {},
  openSigninProcess: false,
  releaseImage: {
    iosIcon: "",
    androidIcon: ""
  },
  androidLatestInstallData: null,
  iosLatestInstallData: null,
  socketStatus: false,
  isUploadingProgress: false,
  expressOptions: {
    headers: {
      common: {
        "Express-Token": StorageService.getItem("express_token")
      }
    }
  }
};

const getters = {
  getAndroidLatestInstallData(state) {
    return state.androidLatestInstallData
  },
  getIosLatestInstallData(state) {
    return state.iosLatestInstallData
  },
  getIsSocketDisconnected (state) {
    return state.socketStatus;
  },
  getIsUploadingReleases (state) {
    return state.isUploadingProgress;
  },
  getReleaseImage (state) {
    return state.releaseImage;
  },
  getReleaseMessage (state) {
    return state.releaseMessage;
  },
  getApkFile (state) {
    return state.apkFileInfo;
  },
  getIpaFile (state) {
    return state.ipaFileInfo;
  }
};

const actions = {
  [GET_RELEASE_INSTALL_LIST] (context, params) {
    let { app_id, release_id, lastInstalledAt } = params;
    return new Promise((resolve, reject) => {
      ApiService.get(
        `portal/app/${app_id}/release/${release_id}/installs?lastInstalledAt=${lastInstalledAt ||
        ""}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [GET_APP_INSTALL_LIST] (context, params) {
    let { app_id, lastInstalledAt } = params;
    return new Promise((resolve, reject) => {
      ApiService.get(
        `portal/app/${app_id}/installs?lastInstalledAt=${lastInstalledAt || ""}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },


  [PRE_RELEASE] (context, params) {
    const url = params.isExpress
      ? `/portal/express/pre-release`
      : `/portal/app/${params.id}/pre-release`;
    return new Promise((resolve, reject) => {
      ApiService.post(
        url,
        params.body,
        params.isExpress
          ? {
            headers: {
              common: {
                "Express-Token": store.getters.expressReleaseToken
              }
            }
          }
          : null
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [RELEASE] (context, params) {
    const url = params.isExpress
      ? `/portal/express/release`
      : `/portal/app/${params.app_id}/release`;
    return new Promise((resolve, reject) => {
      ApiService.post(
        url,
        params.body,
        params.isExpress
          ? {
            headers: {
              common: {
                "Express-Token": store.getters.expressReleaseToken
              }
            }
          }
          : null
      )
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async [RELEASE_UPLOAD] (context, params) {
    const platforms = [
      { ...params.apk, file: state.apkFileInfo },
      { ...params.ipa, file: state.ipaFileInfo }
    ];
    platforms.forEach(item => {
      if (item.url) {
        store.commit(NEW_UPLOAD_START, { type: item.type });
      }
      if (item.url && item.file) {
        upload(item.file, item.url, item.token, item.action, item.type)
      } else {
        if (item.url) {
          store.commit(SET_FAILED, {
            type: item.type,
            status: "failed",
            error: {
              message: "Something went wrong, please select the file again"
            }
          });
        }
      }
    });
  }
};

const mutations = {
  setAndroidLatestInstallData(state, payload) {
     state.androidLatestInstallData = payload
  },
  setIosLatestInstallData(state, payload) {
     state.iosLatestInstallData = payload
  },
  setIsSocketDisconnected (state, payload) {
    state.socketStatus = payload;
  },
  setOpenSigninProcess (state, payload) {
    state.openSigninProcess = payload || false;
  },
  setReleaseImage (state, payload) {
    state.releaseImage = {
      androidIcon: payload.androidIcon ? payload.androidIcon : "",
      androidId: payload.androidId ? payload.androidId : "",
      iosIcon: payload.iosIcon ? payload.iosIcon : "",
      iosId: payload.iosId ? payload.iosId : ""
    };
  },
  removeReleaseImage (state, payload) {
    if (!payload) {
      state.releaseImage = {
        androidIcon: null,
        androidId: null,
        iosIcon: null,
        iosId: null
      };
    }
  },
  setReleaseLoader (state, payload) {
    state.releaseLoader = payload;
  },
  setReleaseMessage (state, payload) {
    state.releaseMessage = payload;
  },
  setIsUploadingReleases (state, payload) {
    state.isUploadingProgress = payload || false;
  },
  setApkFileInfo (state, payload) {
    state.apkFileInfo = payload;
  },
  setIpaFileInfo (state, payload) {
    state.ipaFileInfo = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
